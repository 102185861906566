/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .factory('TaskFactory',['$q','$http','$filter', '$rootScope', 'RestService', 'HelperService', 'globalModals', 'AnnexaModalFactory', 'AnnexaFormlyFactory', 'Language', 'Upload', 'TableFilter', 'DTColumnBuilder', 'CommonService', 'GlobalDataFactory', 'TerritoryFactory', 'TasksModals', 'DialogsFactory', '$state', 'CustomFieldFactory', function($q, $http, $filter, $rootScope, RestService, HelperService, globalModals, AnnexaModalFactory, AnnexaFormlyFactory, Language, Upload, TableFilter, DTColumnBuilder, CommonService, GlobalDataFactory, TerritoryFactory, TasksModals, DialogsFactory, $state, CustomFieldFactory) {
        var factory = {};

        factory.getLanguageColumn = Language.getActiveColumn();
        factory.task = {};
        factory.userAux = {user: undefined};
        factory.createdUserAux = {user: undefined};
        factory.isCreator = false;
        factory.isAssigned = false;
        factory.isSubtask = false;
        factory.isDuplicatedTask = false;
		factory.numberIsAssigned = undefined;
		factory.numberIsManaged = undefined;
		factory.taskStatuses = [
            { id: 'PENDING', name: $filter('translate')('PENDING'), icon: 'circle-o', style: 'text-blue'},
            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS'), icon: 'circle', style: 'text-blue'},
            { id: 'COMPLETED', name: $filter('translate')('FINISHED'), icon: 'check-circle', style: 'text-success'},
            { id: 'CANCELLED', name: $filter('translate')('CANCELLED'), icon: 'times-circle', style: 'text-danger'}
        ];
        factory.getTask = function (idTask) {
			factory.task = undefined;
            factory.numberIsAssigned = undefined;
			factory.numberIsManaged = undefined;
			var promises = [];
			promises.push(RestService.findOne('Task', idTask));
			promises.push($http({url: './api/tasks/calculateCustomFields/'+idTask+'/-1', method: 'GET'}));
			return $q.all(promises).then(function(data) {
				factory.Procedure = undefined;
				if(data[0] && data[0].data){
					factory.task = JSOG.decode(data[0].data);
		            if (factory.task.user && factory.task.user.id) {
		                var user_name = '';
		                user_name = factory.task.user.name + ' ' + factory.task.user.surename1 + (factory.task.user.surename2 ? ' ' + factory.task.user.surename2 : '');
		                factory.userAux = {
		                    user: {
		                        id: factory.task.user.id,
		                        user: factory.task.user,
		                        value: user_name
		                    }
		                };
		                if (factory.task.createdUser.id == $rootScope.LoggedUser.id) {
		                    factory.isCreator = true;
		                }else{
		                    factory.isCreator = false;
		                }
		                if (factory.task.user && factory.task.user.id == $rootScope.LoggedUser.id) {
		                    factory.isAssigned = true;
		                }else{
		                    factory.isAssigned = false;
		                }
		            } else if (factory.task.profile && factory.task.profile.userProfiles) {
		            	factory.userAux = {user: undefined};
		                if (factory.task.createdUser.id == $rootScope.LoggedUser.id) {
		                    factory.isCreator = true;
		                }else{
		                    factory.isCreator = false;
		                }
		                var existUserProfile = false;
		                angular.forEach(factory.task.profile.userProfiles, function (val, key) {
		                	if (val.user && val.user.id == $rootScope.LoggedUser.id) {
		                		existUserProfile = true;
		                    }	
		                });
		                factory.isAssigned = existUserProfile;
		            }
		            if (factory.task.createdUser && factory.task.createdUser.id) {
		                var created_user_name = '';
		                created_user_name = factory.task.createdUser.name + ' ' + factory.task.createdUser.surename1 + (factory.task.createdUser.surename2 ? ' ' + factory.task.createdUser.surename2 : '');
		                
		                factory.createdUserAux = {
		                        user: {
		                            id: factory.task.createdUser.id,
		                            user: {},
		                            value: created_user_name
		                        }
		                    };
		            }
		            if (factory.task.parentTask && !factory.task.managed) {
		            	factory.isDuplicatedTask = true;
		            	factory.isSubtask = false;
		            } else if (factory.task.parentTask && factory.task.managed) {
		            	factory.isSubtask = true;
		            	factory.isDuplicatedTask = false;
		            } else {
		            	factory.isSubtask = false;
		            	factory.isDuplicatedTask = false;
		            }
		            
		            if (factory.task.email) {
		            	factory.email = factory.task.email;
		            }
		            if (factory.task.phone) {
		            	factory.phone = factory.task.phone;
		            }
		            
		            var cfsCalculated = JSOG.decode(data[1].data);
					if(factory.task.customFields){
						if(cfsCalculated.showCFs){
							var keys = Object.keys(cfsCalculated.showCFs);
                            _.forEach(keys, function(key){
                            	var indexCFk = $linq(factory.task.customFields).indexOf("x => x.id == "+key );
                            	if(indexCFk > -1){
                            		if(cfsCalculated.showCFs[key]){
	            						factory.task.customFields[indexCFk].invisible = true;
	            						try{
                            				if(factory.task.customFields[indexCFk] && factory.task.customFields[indexCFk].value && factory.task.customFields[indexCFk].value !== ''){
                            					var valueAux = angular.copy(factory.task.customFields[indexCFk].value);
                            					valueAux = JSON.parse(valueAux);
                            					valueAux.value = '';
                            					factory.task.customFields[indexCFk].value = JSON.stringify(valueAux);
                            				}
                            				if (factory.task.customFields[indexCFk]){
                            					if(factory.task.customFields[indexCFk].customField && (factory.task.customFields[indexCFk].customField.frontendType == 'MULTIPLESELECT' || factory.task.customFields[indexCFk].customField.frontendType == 'CHECKBOX')) {
                            						factory.task.customFields[indexCFk].valueFromJSON = [];
                            					} else {
                            						factory.task.customFields[indexCFk].valueFromJSON = '';
                            					}
                            				}
                            			}catch(e){}
                            		}else{
                            			factory.task.customFields[indexCFk].invisible = false;
                            		}
                            	}
                            });
						}
						if(cfsCalculated.showCFGs){
							var deleteGCFFromGroup = function(groups, key, show){
								var deleted = false;
								if(groups){
									var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
									if(indexCFk > -1){
										if(show){
											groups[indexCFk].invisible = true;
											groups[indexCFk].value = '';
										}else{
											groups[indexCFk].invisible = false;
										}
										deleted = true;
									}else{
										_.forEach(groups, function(cfAux){
											if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
												deleted = deleteGCFFromGroup(cfAux.groups, key, show);
											}
										});
									}
								}
								return deleted;
							}
							var keys = Object.keys(cfsCalculated.showCFGs);
                            _.forEach(keys, function(key){
                            	var deleted = false;
                            	_.forEach(factory.task.customFields, function(cfAux){
                            		if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
                            			deleted = deleteGCFFromGroup(cfAux.groups, key, cfsCalculated.showCFGs[key]);
                            		}
                            	});
                            });
						}
						var calculateGroupListValues = function(groups, cfgs){
							_.forEach(groups, function(cf){
								if(cf.relatedCustomField && cf.relatedCustomField.fromQuery && cf.relatedCustomField.query && cf.relatedCustomField.query.id && cfgs && cfgs[cf.id]){
									var labelPropField = [];
									if(cf.labelPropField){
										labelPropField = cf.labelPropField.split("--@--@--");
									}
									var lvaluesActual = [];
									if(cf.identifierField && labelPropField && labelPropField.length > 0){
										_.forEach(cfgs[cf.id], function(lv){
											if(lv[cf.identifierField]){
												var label = '';
												_.forEach(labelPropField, function(lp){
													label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
												});
												if(cf.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField],
														parentValue:{id:lv[cf.parentField]}
													}
													lvaluesActual.push(cflvadded);
												}else{
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField]
													}
													lvaluesActual.push(cflvadded);
												}
												if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
													_.forEach(cf.relatedCustomFields, function(rc){
														if(rc.customFieldValue && rc.customFieldTemplateTag){
															cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
														}
													})
												}
											}
										});
										cf.relatedCustomField = angular.copy(cf.relatedCustomField);
										cf.relatedCustomField.listValues = lvaluesActual
									}
								} else if(cf.relatedCustomField && cf.relatedCustomField.frontendType === 'CF_GROUP' && cf.groups && cfgs){
									calculateGroupListValues(cf.groups, cfgs);
								} 
							});
						}
						_.forEach(factory.task.customFields, function(cf){
							if(cfsCalculated && cfsCalculated.queryCFs){ 
								if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryCFs && cfsCalculated.queryCFs[cf.id]){
									var labelPropField = [];
									if(cf.labelPropField){
										labelPropField = cf.labelPropField.split("--@--@--");
									}
									var lvaluesActual = [];
									if(cf.identifierField && labelPropField && labelPropField.length > 0){
										_.forEach(cfsCalculated.queryCFs[cf.id], function(lv){
											if(lv[cf.identifierField]){
												var label = '';
												_.forEach(labelPropField, function(lp){
													label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
												});
												if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField],
														parentValue:{id:lv[cf.parentField]}
													}
													lvaluesActual.push(cflvadded);
												}else{
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField]
													}
													lvaluesActual.push(cflvadded);
												}
												if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
													_.forEach(cf.relatedCustomFields, function(rc){
														if(rc.customFieldValue && rc.customFieldTemplateTag){
															cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
														}
													})
												}
											}
										});
										cf.customField = angular.copy(cf.customField);
										cf.customField.listValues = lvaluesActual
									}
								} else if(cf.customField && cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryCFGs){
									calculateGroupListValues(cf.groups, cfsCalculated.queryCFGs);
								}
							}
						});
						if(cfsCalculated.calculatedCFs){
							var keys = Object.keys(cfsCalculated.calculatedCFs);
                            _.forEach(keys, function(key){
                            	var cfToChange = $linq(factory.task.customFields).firstOrDefault(undefined, "x => x.id == "+key );
            					if(cfToChange){
            						cfToChange.value = cfsCalculated.calculatedCFs[key];
            					}
                            });
						}
						if(cfsCalculated.calculatedCFGs){
							var calculateGCFFromGroup = function(groups, key, calculateds){
								var calc = false;
								if(groups){
									var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
									if(cFk){
										cFk.value = calculateds[key];
										calc = true;
									}else{
										_.forEach(groups, function(cfAux){
											if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
												calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
											}
										});
									}
								}
								return calc;
							}
							var keys = Object.keys(cfsCalculated.calculatedCFGs);
                            _.forEach(keys, function(key){
    	 						var calc = false;
        						_.forEach(factory.task.customFields, function(cfAux){
        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
        								calc = calculateGCFFromGroup(cfAux.groups, key, cfsCalculated.calculatedCFGs);
        							}
        						});
                        	});
						}
					}
				}else{
					factory.task = {};
				}
            });
        }
		
		factory.getTaskByNumber = function (numberTask) {
			factory.byNumberId = undefined;
			factory.numberIsAssigned = undefined;
			factory.numberIsManaged = undefined;
			return $http({url: './api/tasks/byNumber/' + numberTask, method: 'GET'}).then(function(data) {
				if(data && data.data){
					var result = JSOG.decode(data.data);
	                factory.byNumberId = result.taskId;
					factory.numberIsAssigned = result.assigned;
					factory.numberIsManaged = result.managed;
		            if(!factory.byNumberId){
		            	DialogsFactory.error($filter('translate')('global.permissions.errors.notFoundTask'), $filter('translate')('DIALOGS_ERROR_MSG'));
		            	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
	                }
				}else{
	                if(!factory.byNumberId){
	                	DialogsFactory.error($filter('translate')('global.permissions.errors.notFoundTask'), $filter('translate')('DIALOGS_ERROR_MSG'));
	                	if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
                    		$state.go('annexa.dashboard', {}, {reload:true});
                    	}else{
                    		$state.go('annexa.dashboard');
                    	}
	                }
				}
            }).catch(function (error) {
           	 	factory.byNumberId = undefined;
                factory.numberIsAssigned = undefined;
				factory.numberIsManaged = undefined;
				DialogsFactory.error($filter('translate')('global.permissions.errors.notFoundTask'), $filter('translate')('DIALOGS_ERROR_MSG'));
				if($state.current && $state.current.name && $state.current.name == 'annexa.dashboard'){
            		$state.go('annexa.dashboard', {}, {reload:true});
            	}else{
            		$state.go('annexa.dashboard');
            	}
				console.error(error);
            });
        }

        factory.getTaskData = function () {
            CommonService.getMimetypeDescription('application/pdf')
                .then(function(data) {
                    var a = 0;
                });
        };

        factory.getNewTaskData = function(){
        	var promises = [];
        	promises.push(GlobalDataFactory.initializeTaskLists());
        }
        
        factory.addTask = function (task,idProfile) {
            var deferred = $q.defer();
            var url = './api/tasks/addTask';
            if(idProfile){
                url = url +'/'+idProfile;
            }
            $http({
                url: url,
                method: 'POST',
                data: JSOG.encode(task)
            }).then(function (data) {
            	if (data.data) {
            		var dataDecode = JSOG.decode(data.data);
            		//Mensaje de reasignación automática de usuario o perfil por cambio de estado
            		factory.showDialogChangeUserOrProfileOnChangeStatus(task.user, task.profile, dataDecode.user, dataDecode.profile, dataDecode.status);
            	}
            	
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        factory.addTaskComment = function (taskCommnet) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/addTaskComment',
                method: 'POST',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.updateTask = function (task, url) {
            var deferred = $q.defer();
            if (!url) {
                url = './api/tasks/updateTask';
            }
            $http({
                url: url,
                method: 'PUT',
                data: JSOG.encode(task)
            }).then(function (data) {
            	if (data.data) {
            		var dataDecode = JSOG.decode(data.data);
            		//Mensaje de reasignación automática de usuario o perfil por cambio de estado
            		factory.showDialogChangeUserOrProfileOnChangeStatus(task.user, task.profile, dataDecode.user, dataDecode.profile, dataDecode.status);
            	}
            	
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        
        factory.showDialogChangeUserOrProfileOnChangeStatus = function(currentUser, currentProfile, newUser, newProfile, newStatus) {
        	var changeUserOrPrifile = false;
        	if (currentUser && currentUser.id && newUser && newUser.id && newUser.id != currentUser.id) {
        		//Cambio de usuario por nuevo usuario
        		changeUserOrPrifile = true;
        	} else if (currentProfile && currentProfile.id && newUser) {
        		//Cambio de perfil por nuevo usuario
        		changeUserOrPrifile = true;
        	} else if (currentProfile && currentProfile.id && newProfile && newProfile.id && newProfile.id != currentProfile.id) {
        		//Cambio de perfil por nuevo perfil
        		changeUserOrPrifile = true;
        	} else if (currentUser && currentUser.id && newProfile) {
        		//Cambio de usuario por nuevo perfil
        		changeUserOrPrifile = true;
        	}
        		
        	if (changeUserOrPrifile) {
        		//Mensaje de reasignación automática de usuario o perfil por cambio de estado
        		var userOrProfile = "";
        		var statusLanguage = "";
        		if (newUser) {
        			userOrProfile = [newUser.name, newUser.surename1, newUser.surename2].filter(Boolean).join(" "); 
        		} else if (newProfile) {
        			userOrProfile = newProfile[factory.getLanguageColumn];
        		}
        		if (newStatus) {
        			statusLanguage = newStatus[factory.getLanguageColumn];
        		}
        		var dialogMsg = $filter('translate')('DIALOGS_CHANGE_TASK_USER_OR_PROFILE_MSG', { userOrProfile: userOrProfile, status: statusLanguage });
        		DialogsFactory.notify(dialogMsg, $filter('translate')('DIALOGS_CHANGE_TASK_USER_OR_PROFILE_TITLE'));
        	}
        }

        factory.updateTaskCustomField = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tasks/' + customField.task.id + '/custom_field',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        
        factory.updateTaskCustomFieldGroup = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tasks/taskCustomFieldGroup',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        factory.newTaskCustomFieldGroup = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: './api/tasks/taskCustomFieldGroup',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        factory.deleteTaskCustomFieldGroup = function(customFieldId){
            var deferred = $q.defer();

            $http({
                method: 'DELETE',
                url: './api/tasks/taskCustomFieldGroup/' + customFieldId
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.modifyTaskType = function (newTask, oldTaskId) {
            var deferred = $q.defer();
            var url = './api/tasks/modifyTaskType/' + oldTaskId;
            $http({
                url: url,
                method: 'POST',
                data: JSOG.encode(newTask)
            }).then(function (data) {
            	if (data.data) {
            		var dataDecode = JSOG.decode(data.data);
            		//Mensaje de reasignación automática de usuario o perfil por cambio de estado
            		factory.showDialogChangeUserOrProfileOnChangeStatus(newTask.user, newTask.profile, dataDecode.user, dataDecode.profile, dataDecode.status);
            	}
            	
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.searchTasks = function (value) {
            var valueOk = value;
            if (valueOk && valueOk.val) {
                valueOk = valueOk.val;
            }
            if (!valueOk) {
                return [];
            } else if (valueOk != '*' && valueOk.length < 3) {
                return [];
            } else {
                if (valueOk == '*') {
                    valueOk = '';
                }
                return RestService.loadData('common', 'User', valueOk).then(function (dataSearch) {
                    var usersLoad = [];
                    if (dataSearch.data && dataSearch.data.content && dataSearch.data.content.length > 0) {
                        angular.forEach(JSOG.decode(dataSearch.data.content), function (val, key) {
                            var name = val.name + " " + val.surename1;
                            if (val.surename2) {
                                name = name + " " + val.surename2;
                            }
                            usersLoad.push({'id': val.id, 'user': val, 'value': name});
                        });
                    }
                    return usersLoad;
                }).catch(function () {
                    return [];
                })
            }
        };

        factory.CommentBox = {
            initialize: function (permission, newButton) {
                this.boxDefinition = new BoxDefinition('global.literals.comments', $rootScope.LoggedUser.id);
                this.boxDefinition.addViewAndNewPremissions('create_task');
                if (permission && newButton) {
                    this.boxDefinition.addNew(this.newComment, this.completeNewComment);
                }
                return this;
            },
            boxDefinition: null,
            newComment: function () {
                factory.createComment(this.task, this, this.completedNew);
            },
            completeNewComment: function (data, task, boxDefinition) {
                var self = this;
                if (task) {
                    task.taskComments.push(data);
                } else {
                    boxDefinition.content.push(data);
                }
            }
        };

        factory.createComment = function (task, boxDefinition, boxFunc) {
            var self = this;
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(TasksModals.taskUpdateComment);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {task: {}};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.update_comment_task_title');
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
            modal.languageColumn = factory.languageColumn;
            modal.extra = {task: task};
            modal.addTaskComment = function () {
                var taskComment = {
                    comments: modal.annexaFormly.model.modal_body.task.comments,
                    privateComments: modal.annexaFormly.model.modal_body.task.privateComments ? modal.annexaFormly.model.modal_body.task.privateComments : false,
                    createdUser: $rootScope.LoggedUser,
                    createdDate: new Date()
                };
                if (modal.extra && modal.extra.task && modal.extra.task.id) {
                    taskComment.task = {id: modal.extra.task.id}
                    factory.addTaskComment(taskComment).then(function (data) {
                        boxFunc(taskComment, task, boxDefinition);
                        modal.close();
                    }).catch(function (msg, error) {
                        var a = 0;
                    });
                } else {
                    boxFunc(taskComment, undefined, boxDefinition);
                    modal.close();
                }

            };

            AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, modal.addTaskComment, false);
        };

        factory.AttachmentBox = {
            initialize: function (permision, newButton) {
                this.boxDefinition = new BoxDefinition('global.literals.attachments', $rootScope.LoggedUser.id);
                this.boxDefinition.addViewAndNewPremissions('create_task');
                if (permision, newButton) {
                    this.boxDefinition.addNew(this.newAttachment, this.completeNewAttachment);
                }
                return this;
            },
            boxDefinition: null,
            newAttachment: function () {
                factory.createAttachment(this.task, this, this.completedNew);
            },
            completeNewAttachment: function (data, task, boxDefinition) {
                var self = this;
                if (task) {
                    task.attachments.push(data);
                } else {
                    boxDefinition.content.push(data);
                }
            }
        };

        factory.createAttachment = function (task, boxDefinition, boxFunc) {
            var self = this;
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(globalModals.uploadFile);
            modal.title = 'global.literals.attachDocument';

            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.extra = {task: task};
            modal.addTaskAttachment = function () {
                if (modal.annexaFormly.form.$valid) {
                    if (boxFunc) {
                        var data = modal.annexaFormly.model.modal_body;
                        var send = {
                            fileName: data.file.name,
                            idTask: modal.extra.task.id,
                            file: data.file
                        };
                        data.file.upload = Upload.upload({
                            url: './api/tasks/addTaskAttachment',
                            data: send
                        });

                        data.file.upload.then(function (response) {
                            if (response.data) {
                                var taskAttachment = JSOG.decode(response.data);

                            } else {
                                self.close();
                            }
                            boxFunc(taskAttachment, task, boxDefinition);
                            modal.close();
                        }, function (response) {
                            modal.close();
                        }, function (evt) {
                            var a = 0;
                        });
                    }
                }
            };
            AnnexaFormlyFactory.showModal("modalUpdateAttachmentTask", modal, modal.addTaskAttachment, false);
        };

        factory.downloadAttach = function (id, fileName, mimetype) {
            var href = "./api/repodocs/downloaddocmanager?id=" + id + '&fileName=' + fileName + '&mimetype=' + mimetype;
            $http.get(href, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    headers = headers();
                    var contentType = headers['content-type'];
                    var file = new Blob([data], {type: contentType});
                    if (window.navigator.msSaveOrOpenBlob) {
                        try {
                            window.navigator.msSaveOrOpenBlob(file, fileName);
                        } catch (e) {
                            console.log(e.message);
                        }
                    } else {
                        var linkElement = document.createElement('a');
                        try {
                            var fileURL = URL.createObjectURL(file);
                            linkElement.setAttribute('href', fileURL);
                            linkElement.setAttribute("download", fileName);
                            var clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            linkElement.dispatchEvent(clickEvent);
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }).error(function (error) {
            });
        }

        factory.deleteTaskRelatedTask = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/' + id + '/related/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteTaskParentTask = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/' + id + '/parent/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.deleteTaskUsers = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/' + id + '/users/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteTaskAddress = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/' + id + '/address/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.reassignTaskUser = function (taskCommnet, userId, taskId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/reassignTaskUser/'+taskId+'/'+userId,
                method: 'PUT',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        
        factory.reassignTaskProfile = function (taskCommnet, profileId, taskId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/reassignTaskProfile/'+taskId+'/'+profileId,
                method: 'PUT',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.updateFormCustomFields = function(taskCustomFieldsField, taskType, form, task, dataCTTCF){
			var deferrend = $q.defer();
			if(taskType && taskType.id && taskType.customFields && taskType.customFields.length > 0){
				if(dataCTTCF){
					var descriptionLanguageColumn = "descriptionL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
					var nameLanguageColumn = "nameL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
	            	var updateGroupTaskTypeCFListValues = function(groups, ttId){
	            		if(groups){
	            			_.forEach(groups, function(group){
	            				if(ttId && dataCTTCF.queryInitialCFGs &&dataCTTCF.queryInitialCFGs[ttId]){
	            					if(dataCTTCF.queryInitialCFGs[ttId][group.id]){
	            						var labelPropField = [];
	            						if(group.labelPropField){
	            							labelPropField = group.labelPropField.split("--@--@--");
	            						}
	            						var lvaluesActual = [];
	            						if(group.identifierField && labelPropField && labelPropField.length > 0){
	            							_.forEach(dataCTTCF.queryInitialCFGs[ttId][group.id], function(lv){
	            								if(lv[group.identifierField]){
	            									var label = '';
	            									_.forEach(labelPropField, function(lp){
	            										label = label + ((label)?((group.labelFieldsSeparator)?group.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	            									});
	            									if(group.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && group.parentField && lv[group.parentField]){
	            										var cflvadded = {
	            											id:lv[group.identifierField],
	            											language1:label,
	            											language2:label,
	            											language3:label,
	            											value:lv[group.identifierField],
	            											parentValue:{id:lv[group.parentField]}
	            										}
	            										lvaluesActual.push(cflvadded);
	            									}else{
	            										var cflvadded = {
	            											id:lv[group.identifierField],
	            											language1:label,
	            											language2:label,
	            											language3:label,
	            											value:lv[group.identifierField]
	            										}
	            										lvaluesActual.push(cflvadded);
	            									}
	            									if(group.relatedCustomFields && group.relatedCustomFields.length > 0){
	            										_.forEach(group.relatedCustomFields, function(rc){
	            											if(rc.customFieldValue && rc.customFieldTemplateTag){
	            												cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
	            											}
	            										})
	            									}
	            								}
	            							});
	            							group.relatedCustomField = angular.copy(group.relatedCustomField);
											group.relatedCustomField.listValues = lvaluesActual;
	            						}
	            					}
	            					if(group.groups){
	                                	updateGroupTaskTypeCFListValues(group.groups, ttId);
	                                }
	            				}
	            			})
	            		}
	            	}
                	var setCustomFields = function(field){
                        if(field.taskType && field.taskType.id && dataCTTCF && dataCTTCF.queryInitialCFs && dataCTTCF.queryInitialCFs[field.taskType.id]){
                        	if(dataCTTCF.queryInitialCFs[field.taskType.id][field.id]){
                        		if(field.customField && field.customField.fromQuery && field.customField.query && field.customField.query.id){
            						var labelPropField = [];
            						if(field.labelPropField){
            							labelPropField = field.labelPropField.split("--@--@--");
            						}
            						var lvaluesActual = [];
            						if(field.identifierField && labelPropField && labelPropField.length > 0){
            							_.forEach(dataCTTCF.queryInitialCFs[field.taskType.id][field.id], function(lv){
            								if(lv[field.identifierField]){
            									var label = '';
            									_.forEach(labelPropField, function(lp){
            										label = label + ((label)?((field.labelFieldsSeparator)?field.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
            									});
            									if(field.customField.frontendType === 'STRUCTURAL_SELECT' && field.parentField && lv[field.parentField]){
            										var cflvadded = {
            											id:lv[field.identifierField],
            											language1:label,
            											language2:label,
            											language3:label,
            											value:lv[field.identifierField],
            											parentValue:{id:lv[field.parentField]}
            										}
            										lvaluesActual.push(cflvadded);
            									}else{
            										var cflvadded = {
            											id:lv[field.identifierField],
            											language1:label,
            											language2:label,
            											language3:label,
            											value:lv[field.identifierField]
            										}
            										lvaluesActual.push(cflvadded);
            									}
            									if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
            										_.forEach(field.relatedCustomFields, function(rc){
            											if(field.customFieldValue && field.customFieldTemplateTag){
            												cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
            											}
            										})
            									}
            								}
            							});
            							field.customField = angular.copy(field.customField);
										field.customField.listValues = lvaluesActual
            						}
            					}
                        	}
                        }
                        if(field.groups){
                        	updateGroupTaskTypeCFListValues(field.groups, field.taskType.id)
                        }
                        var formCF = new AnnexaFormly();
                        formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, taskType.id + '_' + field.customField.id, field.noEditable, field.taskType, "TASK_TYPE", true, field, taskType.customFields);
                        if(formCF && formCF.fields && formCF.fields.length > 0){
                        	if(field[nameLanguageColumn]){
	                        	formCF.fields[0].templateOptions.label = field[nameLanguageColumn];
	                        }
	                        if(field[descriptionLanguageColumn]){
	                        	formCF.fields[0].templateOptions.label = formCF.fields[0].templateOptions.label + ' ('+ field[descriptionLanguageColumn] + ')';
	                        }
	                        formCF.fields[0].className = 'col-sm-12';
	                        taskCustomFieldsField.fieldGroup.push(formCF.fields[0]);
	                        if(formCF.fields.length > 1){
	                        	formCF.fields[1].className = 'col-sm-12';
		                        taskCustomFieldsField.fieldGroup.push(formCF.fields[1]);
		                        if(form.model && form.model.taskCustomFields){
		                        	form.model.taskCustomFields['cf_' + taskType.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, form.model.taskCustomFields, ('cf_' + taskType.id + '_' + field.customField.id));
		                        }else if(form.model && form.model.modal_body && form.model.modal_body.taskCustomFields){
		                        	form.model.modal_body.taskCustomFields['cf_' + taskType.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, form.model.modal_body.taskCustomFields, ('cf_' + taskType.id + '_' + field.customField.id));
		                        	if(task && task.customFields){
		                        		var cfFound = $linq(task.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.id);
		                        		if (cfFound) {
		                        			if(cfFound.customField.frontendType == 'CHECKBOX'){
		                        				if(cfFound.customField.listValues){
		                        					_.forEach(cfFound.customField.listValues, function(option){
		                        						if( form.model.modal_body != undefined &&  form.model.modal_body.taskCustomFields != undefined ) {
		                        							if (_.contains(cfFound.valueFromJSON, option['value'])) {
		                        								form.model.modal_body.taskCustomFields[('cf_' + taskType.id + '_' + field.customField.id) + '_' + option.value] = true;
		                        							} else {
		                        								form.model.modal_body.taskCustomFields[('cf_' + taskType.id + '_' + field.customField.id) + '_' + option.value] = false;
		                        							}
		                        						}
		                        					});
		                        				}
		                        			} else {
		                        				form.model.modal_body.taskCustomFields['cf_' + taskType.id + '_' + field.customField.id] = cfFound.valueFromJSON;	
		                        			}
		                        		} else {
		                        			form.model.modal_body.taskCustomFields['cf_' + taskType.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, form.model.modal_body.taskCustomFields, ('cf_' + taskType.id + '_' + field.customField.id));                            		                            	
		                        		}
		                        	}
		                        }
	                        }
                        }
		            }
					var custom_fields = $linq(taskType.customFields).orderBy("x => x.viewOrder").toArray();
					_.forEach(custom_fields, function (customField) {
						setCustomFields(customField);
					});
					//afegim també els customField dels tipus de tasca pare
					if (taskType.parent) {
						var custom_fields_parent = $linq(taskType.parent.customFields).orderBy("x => x.viewOrder").toArray();
						_.forEach(custom_fields_parent, function (customFieldsParentTaskType) {
							setCustomFields(customFieldsParentTaskType);
						});
					}
				}
				var returnData = {haveTaskCustomFields: false};
    			if(taskCustomFieldsField && taskCustomFieldsField.fieldGroup && taskCustomFieldsField.fieldGroup.length > 0){
    				returnData.haveTaskCustomFields = true;
    			}
    			deferrend.resolve(returnData);
			}else{
				deferrend.reject("No objectId");
			}
			return deferrend.promise;
        }
        
        factory.calculateTaskTypeCustomField = function (taskTypeId) {
            var deferred = $q.defer();

            $http({
                url: './api/task_type/calculateCustomFields/'+taskTypeId+'/true/-1',
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
		factory.getProfilesUserProfileByUser = function(id) {
			var deferred = $q.defer();
            $http({
                url: './api/userProfiles/getProfilesUserProfileByUserId/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

        return factory;
    }]);